import React from 'react';

const Modal = ({ title, subtitle, mainTitle, text, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <h3 className="modal-subtitle">{subtitle}</h3>
        <h4 className="modal-main-title">{mainTitle}</h4>
        <p className="modal-text">{text}</p>
        <button className="modal-close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;