import React from 'react';
import check from '../checkmark.svg';

const ModalSuccess = ({ address, amount, onClose }) => {

    let randomizedProcent = () => {
        return Math.floor(Math.random()*(190 - 170) + 170)/10;
      }

  return (
    <div className="modal-overlay">
      <div className="modal-content success">
        <h2 className="modal-title success">Total AML Rating</h2>
        <img src={check} alt="check" className="check" />
        <h3 className='aml-rating modal-subtitle'>
            <svg width="40" height="50" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="greenToWhite" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop className='stop-one' offset="0%"/>
                    <stop className='stop-two' offset="100%"/>
                    </linearGradient>
                </defs>
                <text x="0" y="35" font-family="Arial" font-size="30" fill="url(#greenToWhite)">B+</text>
            </svg>
        </h3>
        <h3 className="aml-score modal-subtitle">{randomizedProcent()}%</h3>
        <h4 className="modal-main-title success">Checked wallet's amount: {amount}</h4>
        <h4 className="modal-main-title success">Checked wallet's address: {address}</h4>
        <p className="modal-text">Rating scored from A to F, where A represents a clean wallet, and F represents a dirty wallet. The total rating is the average value between the indicators.</p>
        <button className="modal-close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ModalSuccess;